html, body {
    scroll-behavior: smooth;
}
  
body {
    background-color: #eae7dd;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: antialiased;
    color: #4c4b42;
}

.main {
    background-color: #faf9f6;
    -webkit-box-shadow: 0px 0px 100px 0px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 100px 0px rgba(0,0,0,0.2);

    border-bottom: 25px $theme-color solid;
    max-width: 1140px;
}

.footer {
    min-height: calc(50vh - 25px);
}


.image {
    position: relative;

    &::before {
        content: '';
        //background-color: #faf9f6cc;
        //background-color: #4c4b42bb;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &-logo {
        object-fit: contain;
        z-index: 10;
        display: none;
    }
}

.logo-image {
    object-fit: contain;
}