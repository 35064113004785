@font-face {
    font-display: swap;
    font-family: Ivar;
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/ivar/IvarLight.woff2");
  }
  
  @font-face {
    font-display: swap;
    font-family: Ivar;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/ivar/IvarRegular.woff2");
  }
  