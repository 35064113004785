.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
    font-weight: 500;
}

.btn-primary {
    &, &:active, &:hover {
        color: white !important;
    }
}

.bg-image {
    background-position: center; 
    background-size: cover;
}